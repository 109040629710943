<div class="container">
    <div class="row">
         <div class="col-xl-4 col-md-6 col-10 mx-auto p-t-10">
             <div class="card">
                <div class="card-body text-center">
                    <h1 class="display-1">404</h1>
                    <h6 class="card-title">Opps, it seems that this page does not exist.</h6>
                    <p class="card-text"> If you are sure it should, search for it.</p>
                </div>
             </div>
         </div>
    </div>
 </div>
